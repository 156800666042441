const isProduction = process.env.NODE_ENV === 'production'
let baseUrl = ''
let timeout = 0

console.log(process.env.NODE_ENV, 'process.env.NODE_ENV')
if (!isProduction) {
    baseUrl = 'https://internalt.sffix.cn'
    timeout = 30000
} else {
    baseUrl = 'https://shunsh.sffix.cn'
    timeout = 10000
}

export {
    baseUrl,
    timeout
}